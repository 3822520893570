<template>
  <div class="page-container">
    <XeAnimationSubmit
      :action-text="actionText"
      :bottom-text="bottomText"
      :animation="confirmation"
      :dots="dots"
    />
  </div>
</template>

<script>
import { useRouter } from '@/composables/useRouter'
import { ref, onBeforeUnmount, computed, onMounted } from '@vue/composition-api'

import XeAnimationSubmit from '@/components/XeAnimationSubmit/XeAnimationSubmit'
import confirmation from '@/assets/animations/confirmation.json'
import { useDashboardStore } from '@/stores/dashboard'

import { useAppStore } from '@/stores/app'

export default {
  components: {
    XeAnimationSubmit,
  },
  setup() {
    const router = useRouter()
    const dashboardStore = useDashboardStore()

    const appStore = useAppStore()

    const isLoading = ref(true)
    // Variables for animation loop
    const isPerformingStpCheck = ref(false)
    const isPerformingStpApproval = ref(false)
    const isRedirectingToXe = ref(false)
    // Animation variables
    const bottomText = 'This might take a minute or two'
    const actionTexts = computed(() => {
      const submitList = ref(dashboardStore.getPendingActionsSubmitText)
      if (!hasDocumentaryEvidence.value) {
        // Remove document text
        submitList.value = submitList.value.filter((e_, i) => i !== 2)
      }
      if (!hasAuthorizedPersonnel.value) {
        // Remove owners text
        submitList.value = submitList.value.filter((e_, i) => i !== submitList.value.length - 2)
      }
      return submitList.value
    })
    const hasDocumentaryEvidence = computed(() => {
      const documentaryEvidenceStatus = dashboardStore.documentaryEvidenceStatus
      return documentaryEvidenceStatus.length > 0
    })
    const hasAuthorizedPersonnel = computed(() => {
      return dashboardStore.additionalInformationStatus.some((item) => {
        if (item.name === 'AuthorizedPersonnel') {
          return !item.hidden
        }
      })
    })
    const actionCounter = ref(0)
    const loop = ref(1)
    // Amount of loops on the middle cycle, one loop is 3 seconds
    const maxLoops = 6
    const dots = ref('')
    const actionText = computed(() => {
      return `${actionTexts.value[actionCounter.value]}`
    })

    function formatDateToYYYYMMDD(inputDate) {
      // Split the input date string using '-' or '/' as separators
      const parts = inputDate.split(/[-/]/)

      if (parts.length === 3) {
        // Check if there are 3 parts; this implies it's in 'DD-MM-YYYY' or 'DD/MM/YYYY' format
        // Determine the position of the year component based on its length
        const yearPosition = parts[0].length === 4 ? 0 : 2

        // Extract the day, month, and year components based on the year's position
        const day = parts[yearPosition === 0 ? 2 : 0]
        const month = parts[1]
        const year = parts[yearPosition]

        return `${year}-${month}-${day}`
      } else {
        return inputDate
      }
    }

    // Go to Galileo
    const goToTransferXe = async () => {
      isRedirectingToXe.value = true
      // Adds loader to show the almost done message
      await new Promise((r) => setTimeout(r, 5000))
      // remove persisted questionsAndAnswers, documents and auth personnel from store
      dashboardStore.setClearSessionStore()
      window.removeEventListener('beforeunload', window.handleWindowClose)
      const redirectUrl = dashboardStore.getRedirectUrl()
      localStorage.clear()
      window.location = redirectUrl
      return
    }

    // STP approval
    const corpProfile = dashboardStore.corpProfile
    const ekycExceptionCount = ref(0)
    const authorizedPersonnel = dashboardStore.authorizedPersonnel

    // Go to error page
    const goToError = (type) => {
      router.push({
        name: 'PendingActionError',
        props: {
          errorType: type,
        },
      })
    }

    // Handler when the error is not controlled
    const sendExceptionErrorEvent = (ex, location) => {
      const allowedEnvironments = ['integration', 'staging']
      if (allowedEnvironments.includes(config.VUE_APP_ENVIRONMENT)) {
        // Allowed when
        appStore.logException({
          text: `Exception on ${location} retry`,
          exception: ex,
          fireEvent: true,
        })
      }
      // Do we need this????
      // goToError('dashboard')
    }

    // Create EKYC body for stp approval endpoint
    const ekycBody = (stpEkycCheckResponse) => {
      return authorizedPersonnel.map((eachPerson, index) => {
        const role =
          eachPerson.isDirector && eachPerson.isUbo
            ? 'Owner and Director'
            : eachPerson.isDirector
            ? 'Director'
            : eachPerson.isUbo
            ? 'Owner'
            : eachPerson.role
            ? eachPerson.role
            : ''
        return {
          ekycCheck: stpEkycCheckResponse.people[index].ekycCheck,
          userId: stpEkycCheckResponse.people[index].userId,
          name: { firstName: eachPerson.firstName, lastName: eachPerson.lastName },
          address: eachPerson.address,
          country: corpProfile.registrationAddress.country,
          countryOfBirth: eachPerson.countryOfBirth,
          dob: formatDateToYYYYMMDD(eachPerson.dob),
          role,
          nationality: eachPerson.nationality,
          email: eachPerson.email,
        }
      })
    }

    /*
      STP APPROVAL STEP
    */
    const stpApprovalStatus = ref('')
    const riskAssessmentId = ref('')
    const stpApprovalCounter = ref(20)
    const payload = {}

    // Share endpoint call for stp approval endpoint
    const performGetStpApproval = async () => {
      // STP approval status
      // Case 1: Status is completed
      if (stpApprovalStatus.value === 'completed') {
        redirectToGalileo()
        return
      }
      // Case 2: Status is pending and counter reaches 0
      if (stpApprovalCounter.value === 0) {
        await dashboardStore.getStpGetApproval(payload.value, true)
        redirectToGalileo()
        return
      }

      // Count - 1
      stpApprovalCounter.value--

      // Make API call
      const response = await dashboardStore.getStpGetApproval(payload.value)
      // Set approval status
      stpApprovalStatus.value = response.data.riskAssessmentStatus.toLowerCase()

      setTimeout(async () => {
        await performGetStpApproval()
      }, 5000)
    }

    const performStartStpApproval = async (payloadVal) => {
      // Make API call
      const response = await dashboardStore.getStpStartApproval(payloadVal)

      // Set approval id and status
      riskAssessmentId.value = response.data.riskAssessmentId
      stpApprovalStatus.value = response.data.riskAssessmentStatus.toLowerCase()
    }

    const redirectToGalileo = async () => {
      isPerformingStpApproval.value = false
      await goToTransferXe()
      return true
    }

    // Perform STP approval
    const performStpApproval = async (corpProfile, ekycCheck) => {
      isPerformingStpApproval.value = true
      // Payload creation
      const country = corpProfile.registrationAddress.country
      payload.value = { country, ekycCheck }
      try {
        await performStartStpApproval(payload.value)
      } catch (error) {
        sendExceptionErrorEvent(error, 'STP approval')
        // If response is still an error redirect to galileo
        redirectToGalileo()
      }

      // STP approval status
      if (
        stpApprovalStatus.value === 'api error' ||
        stpApprovalStatus.value === 'invalid company'
      ) {
        redirectToGalileo()
        return
      }

      // Set risk assessment id into payload
      payload.value['riskAssessmentId'] = riskAssessmentId.value

      // wait 15s until next call
      setTimeout(async () => {
        await performGetStpApproval()
      }, 15000)
    }

    // Perform EKYC check
    const performEkyc = async (body) => {
      let ekycCheck
      try {
        // Perform EKYC check
        const stpEkycCheckResponse = await dashboardStore.getStpEkycCheck(body)
        // API call response is "OK" create body for next call
        ekycCheck = ekycBody(stpEkycCheckResponse)
        return ekycCheck
      } catch (ex) {
        // API call response is an error retry 1 time
        ekycExceptionCount.value++
        sendExceptionErrorEvent(ex, 'EKYC Check')
        // If response is still an error perform STP approval
        if (ekycExceptionCount.value === 2) {
          return ekycCheck
        } else {
          // API call response is an error retry 1 time
          performEkyc(body)
        }
      }
    }

    // Perform STP check
    const performStpCheck = async () => {
      isPerformingStpCheck.value = true
      let people, address
      let isFixedFormat = Boolean(corpProfile.mainContact.address?.fixedFormat)
      // Check for authorized personnel
      if (authorizedPersonnel && authorizedPersonnel.length > 0) {
        people = authorizedPersonnel.map((eachPerson, index) => {
          if (isFixedFormat) {
            address = { FixedFormat: eachPerson.fixedFormatAddress }
          } else {
            address = { FreeFormat: eachPerson?.freeFormatAddress }
          }
          // suffix not needed for auth signatory
          const suffix = index === 0 ? '' : index < 10 ? `0${index}` : index
          return {
            userId: `${corpProfile.profileId}${suffix}`,
            referenceId: `${corpProfile.profileId}${suffix}`,
            isSignatory: eachPerson?.isSignatory || false,
            name: { firstName: eachPerson.firstName, lastName: eachPerson.lastName },
            dob: formatDateToYYYYMMDD(eachPerson.dob),
            address: address,
            country: corpProfile.registrationAddress.country,
          }
        })
      } else {
        if (isFixedFormat) {
          address = { FixedFormat: corpProfile.mainContact.address.fixedFormat }
        } else {
          address = { FreeFormat: corpProfile.mainContact.address?.freeFormat }
        }
        people = [
          {
            userId: corpProfile.profileId,
            referenceId: corpProfile.profileId,
            isSignatory: true,
            name: {
              firstName: corpProfile.mainContact.firstName,
              lastName: corpProfile.mainContact.lastName,
            },
            dob: formatDateToYYYYMMDD(corpProfile.mainContact.dateOfBirth),
            address: address,
            country: corpProfile.registrationAddress.country,
          },
        ]
      }
      // Perform EKYC check
      const ekycCheck = await performEkyc({
        clientId: corpProfile.clientId,
        clientNumber: corpProfile.clientNumber,
        tbuId: corpProfile.businessUnitId,
        people: people,
      })
      // API call response is "OK" perform STP approval
      if (performEkyc) {
        isPerformingStpCheck.value = false
        const stpApproval = await performStpApproval(corpProfile, ekycCheck)
        if (stpApproval) {
          return true
        }
      }
    }

    onMounted(async () => {
      await performStpCheck()
    })

    const timer = setInterval(() => {
      if (dots.value.length <= 2) {
        dots.value += '.'
      } else {
        // Every 3 seconds resets the dots
        dots.value = ''
        // Action text handling with API Calls
        // Step 1: Starts from 0
        // Step 2: Handles second to second to last values
        if (isPerformingStpApproval.value) {
          // First switch from first text to loop
          if (actionCounter.value === 0) {
            actionCounter.value++
          } else if (
            loop.value === maxLoops &&
            actionCounter.value === actionTexts.value.length - 2
          ) {
            loop.value = 1
            actionCounter.value = 1
          } else if (
            // Between second and second to last values
            actionCounter.value > 0
          ) {
            if (loop.value < maxLoops) {
              loop.value++
            } else {
              loop.value = 1
              actionCounter.value += 1
            }
          }
        }
        if (isRedirectingToXe.value) {
          actionCounter.value = actionTexts.value.length - 1
        }
      }
    }, 1000)

    onBeforeUnmount(() => {
      clearInterval(timer)
    })

    return {
      isLoading,
      confirmation,
      bottomText,
      actionText,
      actionCounter,
      loop,
      dots,
      isPerformingStpCheck,
      isPerformingStpApproval,
      isRedirectingToXe,
      goToError,
    }
  },
}
</script>

<style lang="postcss" scoped>
.page-container {
  @apply flex absolute;
  width: calc(-292px + 100%);
  height: calc(-284px + 100%);
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}
</style>
